import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../utils/constants";

const Produkdetails = (props) => {
  let urlElements = window.location.href.split("/");
  const [product, setPosts] = useState([]);

  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    fectProductbyId();
  }, []);

  const fectProductbyId = async () => {
    const response = await axios.get(API_URL + `prDtl/${id}`);
    const data = await response.data.data;
    setPosts(data);
  };

  return (
    <Fragment>
      <div className="breadcromb-wrapper">
        <div className="breadcromb-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="breadcromb-left">
                {(() => {
                  if (urlElements[4] === "MTR001") {
                    return <h3>Ecolink</h3>;
                  } else if (urlElements[4] === "MTR002") {
                    return <h3>Philips</h3>;
                  } else if (urlElements[4] === "MTR003") {
                    return <h3>Schneider Electric</h3>;
                  } else if (urlElements[4] === "MTR004") {
                    return <h3>APC By Schneider</h3>;
                  } else if (urlElements[4] === "MTR005") {
                    return <h3>Legrand</h3>;
                  } else if (urlElements[4] === "MTR006") {
                    return <h3>3M</h3>;
                  } else if (urlElements[4] === "MTR007") {
                    return <h3>Honeywell</h3>;
                  } else if (urlElements[4] === "MTR008") {
                    return <h3>Cheetah</h3>;
                  } else if (urlElements[4] === "MTR009") {
                    return <h3>Kings</h3>;
                  } else if (urlElements[4] === "MTR010") {
                    return <h3>Karcher</h3>;
                  } else if (urlElements[4] === "MTR011") {
                    return <h3>Socomec	</h3>;
                  } else if (urlElements[4] === "MTR012") {
                    return <h3>Hikvision</h3>;
                  } else if (urlElements[4] === "MTR013") {
                    return <h3>Notifier</h3>;
                  } else if (urlElements[4] === "MTR014") {
                    return <h3>Powercraft</h3>;
                  } else {
                    return <h3>Not Defined!</h3>;
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-page-wrapper our-team-wrapper">
        <div className="container">
          <div className="row">
            {product.map((prd, index) => (
              <div className="col-md-12">
                <div className="team-item">
                  <div className="team-item-image">
                    <img src={prd.detproductUrlimage} alt="" />
                  </div>
                  {/* <div className="team-item-descr font-alt">
             <div className="team-item-name">{prd.detproductTitle} </div>
           </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Produkdetails;
