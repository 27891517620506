
import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import axios from 'axios';
import Moment from 'moment';
import { API_URL } from '../utils/constants';

const fadeImages = [
  {
    url: 'images/banners-bg.jpg',
    caption: 'First Slide'
  },
  {
    url: 'images/banners2-bg.jpg',
    caption: 'Second Slide'
  }
];

const Home = (props) => {
  //define state
  const [webs, setPosts] = useState([]);
  const [prods, setProd] = useState([]); 

  useEffect(() => {
    window.scrollTo(0, 0);
    fectData();
    fectProduct();
    }, []);

    const fectData = async () => {
      const response = await axios.get(API_URL + 'newsupdate');
      const data = await response.data.data;
      setPosts(data);
    }

    const fectProduct = async () => {
      const response = await axios.get(API_URL + 'product');
      const data = await response.data.data;
      setProd(data);
      console.log(data);
    }

  return (
    <Fragment>
     <div className="banner-wrapper">
   <div id="first-slider" className="">
     <div id="carousel-example-generic" className="carousel slide carousel-fade"> 
     
     <Fade>
        {fadeImages.map((fadeImage, index) => (
          <div key={index}>
            <img style={{ width: '100%' }} src={fadeImage.url} />
          </div>
        ))}
      </Fade>
         
       {/* <!-- End Wrapper for slides-->   */}
     
     
     </div>
   </div>
 </div>

 <section className="our-services-wrapper">
   <div className="container">
     <div className="title">
       <h2>Kategori Produk </h2>
       <span className="title-border-color"><i className="fa fa-bolt" aria-hidden="true"></i></span>  </div>
     <div className="row">
     {prods.map((prd, index) => (
       <div className="col-lg-4 col-md-6 col-sm-6">
         <div className="single-service">
           <div className="single-services">
             <div className="services-inner" key={ prd.id }>
               <div className="our-services-icon">  
				        <span>
                  <img src={ prd.productUrlcover } alt="" />
                  {console.log(prd.productUrlcover)}
                </span>
			         </div>               
               <div className="our-services-text">
                 <h3>{ prd.productTitle } </h3>
                 <p>{ prd.productRemarks } </p>
                 <Link to={`/pRd/${prd.productCode}/mas-product`}>SELENGKAPNYA</Link>
                 {/* <Link to="/product/lighting-solution"></Link>  */}
               </div>                  
             </div>
           </div>
         </div>
       </div>
       ))}
     </div>
   </div>
 </section>

 <div className="join-wrapper">
   <div className="container">
     <h3>Layanan Kelistrikan <span>Tercepat dan Terlengkap </span></h3>
    </div>
 </div>
 <section className="news-wrapper">
   <div className="container">
     <div className="title">
       <h2>Berita & Tips </h2>
       <span className="title-border-color"><i className="fa fa-bolt" aria-hidden="true"></i></span>  </div>
     <div className="row">
      {webs.map((post, index) => (
        <div className="col-md-4 col-sm-6" key={ post.id }>
        <div className="news-section-single">
          <div className="news-img-main">
            <div className="news-img">
              <img src={post.newsUrlimage} alt="" data-popupalt-original-title="null" title="" />
              <div className="news-list">
                <ul>
                  <li><i className="fa fa-clock-o" aria-hidden="true"></i> {Moment(post.newsCreatedate).format('DD MMM YYYY')} </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="news-head">
            <h3><Link to={`/iDs/${post.newsSlug}`}>{post.newsTitle}</Link></h3>
          </div>
                                        </div>
                                      </div>
                                    ))}  
            
     
     </div>
   </div>
 </section>
  
 
    </Fragment>
  )
}

export default Home