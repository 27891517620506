import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../utils/constants';

const Product = (props) => {
  const [prods, setProd] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    fectProduct();
  }, []);

  const fectProduct = async () => {
    const response = await axios.get(API_URL + 'product');
    const data = await response.data.data;
    setProd(data);
  }

  return (
    <Fragment>
        {/* Breadcromb Wrapper Start */}
 <div className="breadcromb-wrapper">
   <div className="breadcromb-overlay"></div>
   <div className="container">
     <div className="row">
       <div className="col-sm-12">
         <div className="breadcromb-left">
           <h3>Produk Kami </h3>
         </div>
       </div>
     </div>
   </div>
 </div>

 <div className="inner-page-wrapper our-services-wrapper">
   <div className="container">
     <div className="row">


     {prods.map((prd, index) => (
       <div className="col-lg-4 col-md-6 col-sm-6">
         <div className="single-service">
           <div className="single-services">
             <div className="services-inner"  key={ prd.id }>
               <div className="our-services-icon">  
               <span>
                <img src={ prd.productUrlcover } alt="" />
                </span>
               </div>
               <div className="our-services-text">
                 <h3>{ prd.productTitle } </h3>
                 <p>{ prd.productRemarks } </p>
                 <Link to={`/pRd/${prd.productCode}/mas-product`}>SELENGKAPNYA</Link>
               </div>
             </div>
           </div>
         </div>
       </div>
      ))}
       
      
      
     
     </div>
   </div>
 </div>
 
 
    </Fragment>
  )
}

export default Product