import React, { useEffect } from "react";
import { Fragment } from "react";

const Reward = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <div className="breadcromb-wrapper">
        <div className="breadcromb-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="breadcromb-left">
                <h3>Penghargaan </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-wrapper">
        <div className="container">
          {/* <div className="title">
            <h2>Penghargaan Kami </h2>
            <span className="title-border-color">
              <i className="fa fa-bolt" aria-hidden="true"></i>
            </span>{" "}
          </div> */}
          <div className="mb-30">
            <div className="display-table">
              <div className="display-table-cell width-50">
                <div className="service-image">
                  <div className="border_style top left">
                    <span></span>
                  </div>
                  <div className="border_style bottom right">
                    <span></span>
                  </div>
                  <img src="images/reff2.png" alt="" title="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Reward;
