import React from 'react'
import { Link } from 'react-router-dom'

const NavbarComponent = (props) => {
  return (
    <>
    <header className="wow fadeInDown" data-offset-top="197" data-spy="affix">
   {/* <div className="top-wrapper hidden-xs">
     <div className="container">
       <div className="row">
         <div className="col-lg-7 col-md-6 col-sm-12">
           <div className="guest"> Hi Guest! Welcome to Electric Website </div>
         </div>
         <div className="col-lg-5 col-md-6 hidden-sm">
           <div className="top-header-add">
             <div className="phone"><i className="fa fa-phone" aria-hidden="true"></i>+62 21 - 438938943 </div>
             <div className="book"><a href="javascript:void(0)"><i className="fa fa-globe" aria-hidden="true"></i><span>Call Online </span></a></div>
           </div>
         </div>
       </div>
     </div>
   </div> */}
   <div className="header-inner">
     <div className="container">
       <div className="row">
         <div className="col-lg-3 col-md-3">
           <div className="logo">
           <Link to="/"><img src="images/logotcs.png" alt="#" data-popupalt-original-title="null" title="#" width={275} height={55}/></Link>
           </div>
           <div className="mobile-menu"></div>
         </div>
         <div className="col-lg-9 col-md-9">
           <nav className="navbar navbar-default desktop">
             <div className="navbar-collapse"> 
               {/* <!-- Main Menu --> */}
               <ul id="nav" className="nav menu navbar-nav">
                 <li><Link to="/">BERANDA</Link></li>
                 <li><Link to="/about">TENTANG KAMI</Link></li>
                 <li><Link to="/product">PRODUK</Link></li> 
                 <li><Link to="/news">BERITA</Link></li>                 
                 {/* <li><a href="#">NEWS <i className="fa fa-circle"></i></a>
                   <ul className="dropdown">
                     <li><Link to="/news">NEWS</Link></li>
                     <li><Link to="/gallery">GALLERY</Link></li>
                   </ul>
                 </li> */}
                 <li><Link to="/contact">HUBUNGI KAMI</Link></li>
               </ul>
               {/* <!-- End Main Menu -->  */}
             </div>
           </nav>
         </div>
       </div>
     </div>
   </div>
 </header>
 
    </>
  )
}

export default NavbarComponent