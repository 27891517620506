import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import Moment from 'moment';
import { API_URL } from '../utils/constants';

const Newsdetails = () => {
  //state
  const [mastercategory, setPosts] = useState([]); 
  const [webs, setNewspopular] = useState([]); 
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [cover, setCover] = useState('');
  const [user, setUser] = useState('');
  const [category, setCategories] = useState('');
  const [tanggal, setTanggal] = useState('');
  const [categoryId, setCategoryId] = useState('');

  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
      getNewsById();
      getCategory();
      fectData();
    }, []);

    const getNewsById = async() => {

      const response = await axios.get(API_URL + `iDs/${id}`);
      const data = await response.data.data

      setTitle(data.newsTitle);
      setContent(data.newsContent);
      setCover(data.newsUrlimage);
      setUser(data.newsCreateuser);
      setCategories(data.categoryName);
      setTanggal(data.newsCreatedate);
      setCategoryId(data.categoryCode);
    };

    const getCategory = async () => {
      const response = await axios.get(API_URL + 'category');
      const data = await response.data.data;
      setPosts(data);
    }

    const fectData = async () => {
      const response = await axios.get(API_URL + 'newsupdate');
      const data = await response.data.data;
      setNewspopular(data);
  }

  return (
    <Fragment>
         <div className="breadcromb-wrapper">
   <div className="breadcromb-overlay"></div>
   <div className="container">
     <div className="row">
       <div className="col-sm-12">
         <div className="breadcromb-left">
           <h3>Berita </h3>
         </div>
       </div>
     </div>
   </div>
 </div>

 <div className="breadcromb-text">
   <div className="container">
     <ul>
       <li><Link to="/">Home</Link></li>
       <li><i className="fa fa-angle-right" aria-hidden="true"></i></li>
       <li>Detail Berita </li>
     </ul>
   </div>
 </div>
 <div className="inner-page-wrapper blog-single-area">
   <div className="container">
    <div className="row">
   <div className="col-lg-8 col-md-12">
     <div className="blog-post"> 
       {/* <!--Start single blog post--> */}
       <div className="single-blog-post">
         <div className="img-holder">  
         <img src={cover} alt="" />         
         </div>
         <div className="text-holder">
           <h3 className="blog-title">{title} </h3>
           <div className="meta-box">  <span className="thm-clr">Post Info </span>
             <ul className="meta-info">
               <li><span className="thm-clr">By: </span>  <a href="#">{user} </a></li>
               <li><span className="thm-clr">On: </span>  <a href="#">{Moment(tanggal).format('DD MMM YYYY')} </a></li>
               <li><span className="thm-clr">Category: </span>  <Link to={`/cAt/${categoryId}/XS==`}>{category}</Link> </li>
             </ul>
           </div>
           <div className="text">
             <p className="mar-bottom" dangerouslySetInnerHTML={{ __html: content }}></p>
           </div>      
         </div>
       </div>
     </div>
   </div>
  
   {/* <!--Start sidebar Wrapper--> */}
   <div className="col-xl-4 col-lg-4 col-md-9 col-sm-12">
     <div className="sidebar-wrapper"> 
       {/* <!--Start single sidebar--> */}
       <div className="single-sidebar">
         <form className="search-form" action="#">
           <input placeholder="Search..." type="text" />
           <button type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
         </form>
       </div>
       {/* <!--End single sidebar--> 
       <!--Start single sidebar--> */}
       <div className="single-sidebar">
         <div className="sec-title">
           <h3>Categories </h3>
         </div>
         <ul className="categories clearfix">
         { mastercategory.map((cat, index) => (
           <li>
            <Link to={`/cAt/${cat.categorySlug}`}>{cat.categoryName} {/* <span>(6) </span> */}</Link> 
           </li>
          )) }
         </ul>
       </div>
       {/* <!--End single sidebar--> 
       <!--Start single sidebar--> */}
      
    
       {/* <div className="single-sidebar">
         <div className="sec-title">
           <h3>Popular Post </h3>
         </div>
         <ul className="popular-post">
         { webs.map((post, index) => (
           <li>
             <div className="img-holder">  
              <img src={post.newsUrlimage} alt="" />
               <div className="overlay-style-one">
                 <div className="box">
                   <div className="content">  <a href="#"><i className="fa fa-link" aria-hidden="true"></i></a>  </div>
                 </div>
               </div>
             </div>
             <div className="title-holder">
               <h5 className="post-title"><Link to={`/iDs/${post.newsSlug}`}>{post.newsTitle}</Link></h5>
               <h6 className="post-date"><i className="fa fa-calendar thm-clr" aria-hidden="true"></i> {Moment(tanggal).format('DD MMM YYYY')} </h6>
             </div>
           </li>       
          )) }
         </ul>
       </div> */}


     </div>
   </div>



 </div> 
   </div>
 </div>
 
    </Fragment>
  )
}

export default Newsdetails