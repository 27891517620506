import React from "react";
import { Fragment } from "react";
import { useEffect } from "react";

const SuccessMessage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
        <div className="breadcromb-wrapper">
   <div className="breadcromb-overlay"></div>
   <div className="container">
     <div className="row">
       <div className="col-sm-12">
         <div className="breadcromb-left">
           <h3>HUBUNGI KAMI </h3>
         </div>
       </div>
     </div>
   </div>
 </div>
 
      <section className="about-wrapper">
        <div className="container">
          <div className="title">
            <h2>Thank's For Your Attention! </h2>
            <span className="title-border-color">
              <i className="fa fa-bolt" aria-hidden="true"></i>
            </span>{" "}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default SuccessMessage;
