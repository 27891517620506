import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import { API_URL } from "../utils/constants";

const Gallery = () => {
  const [webs, setPosts] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fectData();
  }, []);

  const fectData = async () => {
    const response = await axios.get(API_URL + `gallery`);
    const data = await response.data.data;
    setPosts(data);
  };

  return (
    <Fragment>
      <div className="breadcromb-wrapper">
        <div className="breadcromb-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="breadcromb-left">
                <h3>Gallery </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-page-wrapper news-wrapper">
        <div className="container">
          <div className="row">
            {webs.map((post, index) => (
              <div className="col-md-4 col-sm-6">
                <div className="news-section-single">
                  <div className="news-img-main">
                    <div className="news-img">
                      <img
                        src={post.galmasterUrlcover}
                        alt=""
                        data-popupalt-original-title="null"
                        title=""
                      />
                    </div>
                  </div>
                  <div className="news-head">
                    <h3>
                      <Link to={`/gLRy/${post.galmasterCode}`}>{post.galmasterName}</Link>
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Gallery;
