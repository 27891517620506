import React, { Fragment, useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../utils/constants';

const Newsbycategories = (props) => {
    const [catbyId, setPosts] = useState([]);
    // const [content, setContent] = useState('');

    const { id } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
        fectCategorybyId();
    }, []);

    const fectCategorybyId = async () => {
      const response = await axios.get(API_URL + `category/${id}`);
      const data = await response.data.data;

      // setContent(data.newsContent);
      setPosts(data);
    }

  return (
    <Fragment>
      <div>
        <div className="breadcromb-wrapper">
   <div className="breadcromb-overlay"></div>
   <div className="container">
     <div className="row">
       <div className="col-sm-12">
         <div className="breadcromb-left">
           <h3>Our News </h3>
         </div>
       </div>
     </div>
   </div>
 </div>

 <div className="inner-page-wrapper news-wrapper">
 <div className="container">
 <div className="row">

 {catbyId.map((cat, index) => (

       <div className="mb-30">
        <div className="display-table">
         <div className="display-table-cell width-50">
           <div className="service-image">
             <div className="border_style top left"><span></span></div>
             <div className="border_style bottom right"><span></span></div>
             <img src={cat.newsUrlimage} alt="" title="" style={{ width: "100%", height: "10%" }} /></div>
         </div>
         <div className="display-table-cell width-50">
           <div className="about-text-area">
             <h3>{cat.newsTitle} </h3>
             
             {/* <p>{cat.newsContent.substring(0, 100)} ... </p>   */}
             <p className="mar-bottom" dangerouslySetInnerHTML={{ __html: cat.newsContent.substring(0, 120) }}></p>
             <Link to={`/iDs/${cat.newsSlug}`}>Read More</Link>
             </div>
         </div>
        </div>
        <br />
       </div> 
       ))}
  </div>
	 </div>
   </div>
   </div>
    </Fragment>
  )
}

export default Newsbycategories