import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { API_URL } from "../utils/constants";

const GalleryDetails = () => {
  const [gallery, setPosts] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    getGalleryById();
  }, []);

  const getGalleryById = async () => {
    const response = await axios.get(API_URL + `gallery/${id}`);
    const data = await response.data.data;

    setPosts(data);

    console.log(data);
  };

  return (
    <Fragment>
      <div className="breadcromb-wrapper">
        <div className="breadcromb-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="breadcromb-left">
                <h3>Gallery Event </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-page-wrapper gallery-wrapper">
        <div className="portfolio-page">
          <div className="container-fluid">
            <div className="row">
              <div className="gallery-section">
                {gallery.map((prd, index) => (
                  <div
                    className="gallery-caption col-md-4 col-sm-4 col-xs-12"
                    key={prd.id}
                  >
                      {" "}
                      <img
                        alt=""
                        src={prd.galdetailsUrlimage}
                        className="img-responsive"
                      />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GalleryDetails;
