import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../utils/constants";

const Produkmaster = (props) => {
  let urlElements = window.location.href.split("/");
  const [product, setPosts] = useState([]);

  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    fectProductbyId();
  }, []);

  const fectProductbyId = async () => {
    const response = await axios.get(API_URL + `pRd/${id}`);
    const data = await response.data.data;

   
    setPosts(data);

    console.log(data);
  };

  return (
    <Fragment>
      <div className="breadcromb-wrapper">
        <div className="breadcromb-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="breadcromb-left">
                {(() => {
                  if (urlElements[4] === "PRD001") {
                    return <h3>Solusi Pencahayaan</h3>;
                  } else if (urlElements[4] === "PRD002") {
                    return <h3>Peralatan Listrik</h3>;
                  } else if (urlElements[4] === "PRD003") {
                    return <h3>Alat Pelindung Kerja</h3>;
                  } else if (urlElements[4] === "PRD004") {
                    return <h3>Solusi Teknologi Pembersih</h3>;
                  } else if (urlElements[4] === "PRD005") {
                    return <h3>Kontrol Daya</h3>;
                  } else if (urlElements[4] === "PRD006") {
                    return <h3>Perangkat Keamanan Gedung</h3>;
                  } else {
                    return <h3>Not Defined!</h3>;
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-page-wrapper our-team-wrapper">
        <div className="container">
          <div className="row">
            {product.map((prd, index) => (
              <div className="col-md-3 col-sm-6" key={prd.id}>
                <div className="team-item">
                  <div className="team-item-image">
                    <img src={prd.masproductUrlimage} alt="" />
                  </div>
                  <div className="team-item-descr font-alt">
                    <div className="team-item-name">
                      <Link to={`/prDtl/${prd.masproductCode}/det-product`}>
                        SELENGKAPNYA
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Produkmaster;
