import React from 'react'

import { Routes, Route } from "react-router-dom";
import FooterComponent from "./components/FooterComponent";
import NavbarComponent from "./components/NavbarComponent";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import News from "./pages/News";
import Product from "./pages/Product";
import Gallery from './pages/Gallery';
import Newsdetails from './pages/Newsdetails';
import Produkmaster from './pages/product/Produkmaster';
import Produkdetails from './pages/product/Produkdetails';
import Newsbycategories from './pages/news/Newsbycategories';
import SuccessMessage from './pages/SuccessMessage';
import Referencee from './pages/Referencee';
import Reward from './pages/Reward';
import GalleryDetails from './pages/GalleryDetails';

function App() {
  return (
    <>
      <NavbarComponent />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/product" element={<Product />} />
            <Route path="/pRd/:id/mas-product" element={<Produkmaster />} />
              <Route path="/prDtl/:id/det-product" element={<Produkdetails />} />
              {/* Route News by categories */}
              <Route path="/cAt/:id" element={<Newsbycategories />} />

          <Route path="/news" element={<News />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gLRy/:id" element={<GalleryDetails />} />

          <Route path="/contact" element={<Contact />} />
          <Route path="/iDs/:id" element={<Newsdetails />} />
          <Route path="success" element={<SuccessMessage />} />
          <Route path="referencee" element={<Referencee />} />
          <Route path="reward" element={<Reward />} />
                    
        </Routes>
      <FooterComponent />
    </>
  );
}

export default App;
