import React, { Fragment, useEffect, useState, useRef } from "react";
//import axios
import axios from "axios";
import { API_URL } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const form = useRef();
  const recaptcha = useRef();
  //state
  const [names, setNames] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [remarks, setRemarks] = useState("");

  //state validation
  const [validation, setValidation] = useState({});

  //history
  const navigate = useNavigate();

  //method "storePost"
  const storePost = async (e) => {
    e.preventDefault(); // agar tidak loading saat submit form

    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      alert("please verify the reCAPTCHA!");
    } else {
      emailjs.sendForm(
        "service_qr46w88",
        "template_4cla4ok",
        form.current,
        "RgPozgehCYU6BjHyS"
      );
      // .then(
      //   (result) => {
      //     console.log(result.text);
      //     console.log("Berhasil Kirim Email!");
      //   },
      //   (error) => {
      //     console.log(error.text);
      //   }
      // );
      //send data to server
      await axios
        .post(API_URL + `sendMessage`, {
          name: names,
          email: email,
          subject: subject,
          phone: phone,
          remarks: remarks,
        })
        .then(() => {
          //redirect
          navigate("/success");
        })
        .catch((error) => {
          setValidation(error.response.data);
        });
    }
  };

  return (
    <Fragment>
      {/* Breadcromb Wrapper Start */}
      <div className="breadcromb-wrapper">
        <div className="breadcromb-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="breadcromb-left">
                <h3>HUBUNGI KAMI </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcromb Wrapper End */}

      {/* <div className="google-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d247.9316938362128!2d106.81687352397596!3d-6.143179600299684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f60770f437e5%3A0x76f9e380b1e9a13!2sTango%20Musik%20Jakarta!5e0!3m2!1sen!2sid!4v1701420339844!5m2!1sen!2sid"
          width="600"
          height="150"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div> */}

      <div className="inner-page-wrapper contact-wrapper">
        <div className="container">
          <div className="title">
            <h2>Kirim Pesan </h2>
            <span className="title-border-color">
              <i className="fa fa-bolt" aria-hidden="true"></i>
            </span>{" "}
          </div>

          {validation.message && (
            <section className="testimonials-wrapper">
              <div className="container">
                <div className="title">
                  <h3 className="h3sam">
                    <strong>{validation.message} </strong>
                  </h3>{" "}
                </div>
              </div>
            </section>
          )}

          <div className="row">
            <div className="col-sm-8 col-sm-offset-2 col-xs-offset-0">
              <div className="contact-form">
                <form ref={form} onSubmit={storePost}>
                  <div className="row">
                    <div className="col-sm-6">
                      <input
                        className="con-field"
                        type="text"
                        value={names}
                        name="user_name"
                        onChange={(e) => setNames(e.target.value)}
                        placeholder="Name"
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        className="con-field"
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        placeholder="Subject"
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        className="con-field"
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Phone"
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        className="con-field"
                        type="email"
                        name="user_email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      />
                    </div>
                    <div className="col-sm-12">
                      <textarea
                        className="con-field"
                        name="message"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        placeholder="Your Message"
                      ></textarea>
                    </div>
                    <div className="col-sm-12">
                      <ReCAPTCHA
                        ref={recaptcha}
                        sitekey={process.env.REACT_APP_SITE_KEY}
                      />
                    </div>
                    <div className="col-sm-12">
                      {/* <button type="submit">Sign up</button> */}
                      <input className="btn-one" type="submit" value="Kirim" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Inner Page Wrapper End */}
    </Fragment>
  );
};

export default Contact;
