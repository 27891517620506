import React, { Fragment, useEffect } from "react";

const About = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      {/* Breadcromb Wrapper Start */}
      <div className="breadcromb-wrapper">
        <div className="breadcromb-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="breadcromb-left">
                <h3>Tentang Kami </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Breadcromb Wrapper End */}

      {/* Inner Page Wrapper Start */}
      <div className="inner-page-wrapper about-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="about-carousel">
                <div className="service-image">
                  <div className="border_style top left">
                    <span></span>
                  </div>
                  <div className="border_style bottom right">
                    <span></span>
                  </div>
                  <img src="images/about-us-img.jpg" alt="" title="" />
                </div>
                <div className="about-caption">
                  <h3>Sejarah Perusahaan </h3>
                  <p>
                    PT Telesindo Citra Sejahtera memulai bisnis sebagai
                    distributor resmi untuk Schneider Electric sejak tahun 1996,
                    kemudian berkembang menjadi distributor resmi beberapa merek
                    kenamaan lainnya, seperti Legrand, APC, dan Ecolink.
                  </p>
                  <p>
                    Tak hanya itu, kami juga melayani penjualan beragam produk
                    dari brand internasional lainnya, termasuk Notifier,
                    Karcher, Cheetah, Kings, Himel, 3M, Socomec, Hikvision, dan
                    Honeywell.
                  </p>
                  <p>
                    Telesindo hadir sebagai game-changer dalam industri
                    elektrikal dan mekanikal. Visi kami adalah untuk
                    memperkenalkan dan melayani berbagai macam produk listrik
                    profesional berkualitas tinggi untuk bangunan dan industri.
                  </p>
                  <p>
                    Prinsipal dan pelanggan adalah mitra kami dalam berbisnis.
                    Telesindo hadir menjadi solusi terbaik bagi kepentingan
                    semua.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="faq-area">
                <h3>Visi dan Misi </h3>
                <div className="about-faq-wrapper">
                  <div
                    className="panel-group panel-faq"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div className="panel panel-default">
                      <div
                        className="panel-heading active"
                        role="tab"
                        id="heading1"
                      >
                        <h4 className="panel-title">
                          {" "}
                          <a
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapse1"
                            aria-expanded="false"
                            aria-controls="collapse1"
                            className="collapsed"
                          >
                            Visi{" "}
                          </a>{" "}
                        </h4>
                      </div>
                      <div
                        id="collapse1"
                        className="panel-collapse collapse in"
                        role="tabpanel"
                        aria-labelledby="heading1"
                        aria-expanded="false"
                      >
                        <div className="panel-body">
                          <p>
                            Menjadi perusahaan yang unggul dan tangguh di bidang
                            mekanikal dan elektrikal yang sangat dibutuhkan
                            dalam pembangunan di Indonesia dan mampu menghadapi
                            persaingan global.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default">
                      <div className="panel-heading" role="tab" id="heading2">
                        <h4 className="panel-title">
                          {" "}
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapse2"
                            aria-expanded="false"
                            aria-controls="collapse2"
                          >
                            Misi{" "}
                          </a>{" "}
                        </h4>
                      </div>
                      <div
                        id="collapse2"
                        className="panel-collapse collapse"
                        role="tabpanel"
                        aria-labelledby="heading2"
                        aria-expanded="false"
                      >
                        <div className="panel-body">
                          <p>
                            - Mengutamakan kualitas dan pelayanan untuk kepuasan
                            pelanggan. <br />- Menjadi mitra bisnis yang andal
                            dan tepercaya. <br />
                            - Menjadi aset yang berharga dan membanggakan bagi
                            masyarakat, bangsa, dan negara. <br />- Menyediakan
                            produk yang inovatif dan berkualitas TERBAIK dengan
                            harga yang kompetitif.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Inner Page Wrapper End  */}
    </Fragment>
  );
};

export default About;
